import { AriaLogger, LoggerType } from "@shennong/web-logger";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { currentApp } from "../config/app";
import { ARIA_TOKEN, VERSION } from "../config/config";
import { guid } from "../lib/guid";
import { getFeedsLanguage } from "./feedsLanguage";
import { SubEntity, SubEntityType } from "./share";

const connection: any = (navigator as any).connection || (navigator as any).webkitConnection;
export const logger = ARIA_TOKEN
  ? new AriaLogger(ARIA_TOKEN, {
      logTableName: "logging",
      telemetryTableName: "telemetry",
      timeTableName: "performance",
      context: {
        // https://www.aria.ms/developers/deep-dives/common-properties/
        "AppInfo.Version": VERSION,
        "AppInfo.Id": currentApp.appName,
        "DeviceInfo.NetworkType": connection?.type || connection?.effectiveType,
      },
    })
  : undefined;

interface ResourcePerformance extends PerformanceEntry {
  initiatorType?: string;
  responseEnd?: number;
  transferSize?: number;
}

function logTime(action: string, duration: number, params?: any) {
  const d = Math.ceil(duration);
  logger?.time(action, d, params);
  return d;
}
function perfLogResource(p: ResourcePerformance) {
  logTime(p.initiatorType ? `${p.entryType}-${p.initiatorType}` : p.entryType, p.duration, {
    src: p.name,
    size: p.transferSize,
  });
}
function perfLogPaint(p: ResourcePerformance) {
  logTime(p.name, p.duration + p.startTime, { paintDuration: p.duration });
}

if (logger) {
  window.addEventListener(
    "load",
    ev => {
      performance.getEntriesByType("paint").forEach(perfLogPaint);
      const resPerfs = performance.getEntriesByType("resource");
      resPerfs.forEach(perfLogResource);
      const js = resPerfs.filter((ele: ResourcePerformance) => ele.initiatorType === "script");
      const startTime = Math.min(...js.map(j => j.startTime));
      const endTime = Math.max(...js.map((j: ResourcePerformance) => j.responseEnd!));
      logTime("download-script", endTime - startTime);

      // https://developer.mozilla.org/en-US/docs/Web/Performance/Navigation_and_resource_timings
      const timeInfo = performance.timing;
      logTime("page-ttfb", timeInfo.responseStart - timeInfo.navigationStart);
      const loadDuration = logTime(
        "page-loading",
        timeInfo.loadEventStart - timeInfo.navigationStart
      );
      const detail = {
        Cache: timeInfo.domainLookupStart - timeInfo.fetchStart,
        Dns: timeInfo.domainLookupEnd - timeInfo.domainLookupStart,
        Connect: timeInfo.connectEnd - timeInfo.connectStart,
        Request: timeInfo.responseStart - timeInfo.requestStart,
        Download: timeInfo.responseEnd - timeInfo.responseStart,
        Dom: timeInfo.domComplete - timeInfo.responseEnd,
        // contentload: timeInfo.loadEventEnd - timeInfo.loadEventStart,
      };
      Object.entries(detail).forEach(([k, v]) => {
        v = logTime(`timing:${k}`, v);
        detail[k as keyof typeof detail] = v;
      });

      logger?.telemetry("performance-timing", {
        Page: window.location.href,
        Duration: loadDuration,
        Redirect: Math.ceil(timeInfo.redirectEnd - timeInfo.redirectStart),
        ...detail,
      });
    },
    false
  );
  document.addEventListener(
    "DOMContentLoaded",
    event => {
      performance.getEntriesByType("navigation").forEach(p => logTime(p.entryType, p.duration));
    },
    false
  );
}

export const REQUEST_ID_KEY = "Request-Id";

/**
 * axios 请求注入开始时间戳
 * @param config
 * @returns
 */
export function axiosTimeLogger(config: AxiosRequestConfig) {
  config._startTime = performance.now();
  let url = config.url || "";
  if (url.includes("?")) {
    url = url.split("?")[0];
  }
  config._originAPI = url;
  return config;
}

/**
 * axios请求header中注入requestId
 * @param config
 */
export function axiosRequestIdLogger(config: AxiosRequestConfig) {
  const headers = config.headers;
  if (!headers) {
    config.headers = { [REQUEST_ID_KEY]: guid() };
  } else if (!headers[REQUEST_ID_KEY]) {
    headers[REQUEST_ID_KEY] = guid();
  }
  return config;
}

function logAxiosTime(
  logType: string,
  statusCode: number,
  startTime: number,
  config: AxiosRequestConfig
) {
  logger?.log(LoggerType.Time, {
    Action: `http-${statusCode}`,
    Duration: Math.ceil(performance.now() - startTime),
    EventType: `request-${logType}`,
    HttpMethod: config.method || "GET",
    ActionParameter: {
      api: config._originAPI || config.url!,
      status: statusCode,
      requestId: config.headers?.["Request-Id"] || "",
    },
  });
}

/**
 * 生成 axios logger
 * @param logType
 */
export function createAxiosResponseLogger(logType: "api" | "graph" | "blob") {
  return (res: AxiosResponse) => {
    const startTime = res.config._startTime;
    if (startTime) {
      logAxiosTime(logType, res.status, startTime, res.config);
    }
    return res;
  };
}

export function createAxiosErrorLogger(logType: "api" | "graph" | "blob") {
  return (err: AxiosError) => {
    const config = err.config;
    if (!config) {
      logger?.error(`${err}`, "http-unkown");
    } else {
      const startTime = config._startTime;
      const status = err.response?.status;
      if (status && startTime) {
        logAxiosTime(logType, status, startTime, config);
      }
      const response = err.response;
      const data: Record<string, string | number> = response?.data as any;
      logger?.error(
        JSON.stringify({
          url: config.url,
          requestId: config.headers?.["Request-Id"],
          status: data?.code || response?.status,
          message: data?.message || response?.statusText || data,
          error: err.message,
        }),
        `http-${status || "fail"}`
      );
    }
    return Promise.reject(err);
  };
}

export function logEnterAppTelemetry(subEntityId?: string): void {
  const parameter: {
    [name: string]: string | number | boolean | Date;
  } = {};
  const subEntity: SubEntity = subEntityId && JSON.parse(subEntityId);

  parameter.FeedsLanguage = getFeedsLanguage("user");

  if (subEntity) {
    parameter.WorkId = subEntity.docId || subEntity.bundleId || subEntity.categoryId || "";

    const source =
      subEntity.from === "bot"
        ? "Notification"
        : subEntity.from === "share"
        ? "DeepLink"
        : subEntity.from === "subscription"
        ? "Subscription"
        : "";
    const type =
      subEntity.type === SubEntityType.document
        ? "Document"
        : subEntity.type === SubEntityType.bundle
        ? "Bundle"
        : "Welcome";
    if (source && type) {
      parameter.EnterType = `${source}.${type}`;
    }

    const documentType = subEntity.type === SubEntityType.document ? subEntity.documentType : "";
    if (documentType) {
      parameter.DocumentType = documentType;
    }

    if (subEntity.scope) {
      parameter.Scope = subEntity.scope;
    }
  }

  logger?.telemetry("EnterApp", parameter);
}
