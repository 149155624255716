// import { FolderItemInfo } from "./request";
import { currentApp } from "../config/app";
import { ResourceScope } from "../config/rc";
import i18n from "../config/i18n";
import { Common } from "../locales";

export function copyToClip({ shareLink: link, title, coverImg }: ShareInfo) {
  if (!link) {
    return;
  }

  function listener(e: any) {
    const html = `
<div>
  <a href="${link}">
    ${title} (${i18n.t(Common.title, { context: "short" })})
  </a>
</div>
<div>
  <a href="${link}">
    <img alt="cover" src="${coverImg}"/>
  </a>
</div>
`;
    e.clipboardData.setData("text/html", html);
    e.clipboardData.setData("text/plain", link);
    e.preventDefault();
  }
  const aux = document.createElement("input");
  aux.setAttribute("value", link);
  document.body.appendChild(aux);
  aux.select();
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
  document.body.removeChild(aux);
}
export enum SubEntityType {
  Link = "link",
  bundle = "bundle",
  document = "view",
  category = "category",
}

export interface SubEntity {
  type: SubEntityType;
  documentType?: RCDocumentType;
  body?: string;
  appName: string;
  from: "bot" | "subscription" | "share";
  bundleId?: string;
  categoryId?: string;
  docId?: string;
  scope?: ResourceScope;
}

export enum RCDocumentType {
  TingDoc = "tingdoc",
  PPTTemplate = "ppt-template",
  WordTemplate = "word-template",
  ExcelTemplate = "excel-template",
  Video = "video",
  ExcelFile = "excel-file",
  PDFFile = "pdf-file",
  PPTFile = "ppt-file",
  WordFile = "word-file",
}

export function getDocExtension(docTyle?: string) {
  switch (docTyle) {
    case RCDocumentType.PPTTemplate:
      return "ppt";
    case RCDocumentType.WordTemplate:
      return "doc";
    case RCDocumentType.ExcelTemplate:
      return "xls";
    case RCDocumentType.Video:
      return "mp4";
    case RCDocumentType.TingDoc:
      return "tingdoc";
    case RCDocumentType.ExcelFile:
      return "xls";
    case RCDocumentType.PDFFile:
      return "pdf";
    case RCDocumentType.PPTFile:
      return "pptx";
    case RCDocumentType.WordFile:
      return "doc";
    default:
      return undefined;
  }
}

function createTabDeepLink(
  appId: string,
  entityId: string,
  context?: {
    subEntityId?: string;
    channelId?: string;
  }
): string {
  return `https://teams.microsoft.com/l/entity/${appId}/${entityId}?context=${
    context && encodeURIComponent(JSON.stringify(context))
  }`;
}

export type Entity = CDS.Document | CDS.Bundle;

export enum ShareLinkType {
  DeepLink = "DeepLink",
  NormalLink = "NormalLink",
}
export interface ShareInfo {
  linkType: ShareLinkType;
  title?: string;
  coverImg?: string;
  shareLink?: string;
  entityType: EntityType;
  id?: string;
}

export enum SourcePageType {
  Bundle = "Bundle",
  Category = "Category",
}

export enum EntityType {
  Document = "Document",
  Bundle = "Bundle",
  // FolderItem = "folderItem",
}
interface EntityInfo {
  [EntityType.Document]: CDS.Document;
  [EntityType.Bundle]: CDS.Bundle;
  // [EntityType.FolderItem]: FolderItemInfo;
}

export type EntityInfoOfKey<T> = T extends keyof EntityInfo ? EntityInfo[T] : never;

export function generateRCShareInfo<T extends keyof EntityInfo>(
  scope: ResourceScope,
  contentType: T,
  content?: EntityInfoOfKey<T>,
  entityId?: string,
  sourcePageType?: SourcePageType
): ShareInfo | undefined {
  let title;
  let coverImg;
  // let shareLink;
  // let subEntityType: SubEntityType = SubEntityType.Link;
  const subEntity: SubEntity = {
    scope,
    appName: currentApp.appName,
    type: SubEntityType.Link,
    from: "share",
  };
  if (content) {
    switch (contentType) {
      case EntityType.Document:
        const document = content as EntityInfoOfKey<EntityType.Document>;
        title = document.metadata?.name;
        coverImg = document.metadata?.cover;
        subEntity.docId = document.id;
        subEntity.type = SubEntityType.document;
        subEntity.documentType = document.metadata?.type as unknown as RCDocumentType;
        subEntity.bundleId =
          sourcePageType === SourcePageType.Bundle ? document.extended?.bundleId : undefined;
        subEntity.categoryId = document.extended?.categoryId;
        break;
      case EntityType.Bundle:
        const bundle = content as EntityInfoOfKey<EntityType.Bundle>;
        title = bundle.name;
        coverImg = bundle.cover;
        subEntity.type = SubEntityType.bundle;
        subEntity.bundleId = bundle.id;
        subEntity.categoryId = bundle.extension?.categoryId;
        break;
    }
    const linkType = entityId ? ShareLinkType.DeepLink : ShareLinkType.NormalLink;
    return {
      id: content.id,
      linkType,
      coverImg,
      title,
      shareLink: createTabDeepLink(currentApp.appId, entityId!, {
        subEntityId: JSON.stringify(subEntity),
      }),
      entityType: contentType,
    };
  } else {
    return undefined;
  }
}

export type SenarioType = "template" | "tingdoc";
