import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { lngResources } from "../locales";
import { APP_ENV, INIT_QUERY } from "./config";

const STORAGE_KEY = "language";

export function getLngFromnavigator(nav: Partial<Navigator>) {
  return (
    nav.language ??
    nav.languages?.find(item => item.toLowerCase() in lngResources) ??
    nav.languages?.find(item => item.replace(/[-_].*/, "") in lngResources)
  );
}

export function getLng() {
  return (
    localStorage.getItem(STORAGE_KEY) ??
    INIT_QUERY.get(STORAGE_KEY) ??
    getLngFromnavigator(navigator)
  );
}

/**
 * 设置语言
 * @param lng
 */
export function setLng(lng: string) {
  i18n.language = lng;
  localStorage.setItem(STORAGE_KEY, lng);
  i18n.changeLanguage(lng);
  // update app language in html (for translator and screen reader)
  document.documentElement.lang = i18n.languages[0];
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getLng(),
    fallbackLng: "en",
    debug: APP_ENV === "development",
    lowerCaseLng: true,
    cleanCode: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: lngResources,
  });
export default i18n;

// update APP language
document.documentElement.lang = i18n.languages[0];
