import React from "react";
import {
  CommandBar,
  ICommandBarItemProps,
  makeStyles,
  mergeStyles,
  ZIndexes,
} from "@fluentui/react";

import { rem } from "../../lib/unit";
import { isTeamsApp } from "../../config/app";
import { isMobliePhone } from "../../config/browser";

const topHeight = rem(55);

export const useTopBarStyles = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    height: topHeight,
    marginTop: isTeamsApp && !isMobliePhone ? rem(-4) : rem(4),
    borderBottom: `1px solid ${theme.palette.neutralLight}`,
  },
  commandBar: {
    boxSizing: "border-box",
    position: "fixed",
    // top: isTeamsApp && !isMobliePhone ? rem(-4) : rem(2),
    width: "100%",
    height: topHeight,
    zIndex: ZIndexes.Nav,
    " div": { height: "100%" },
    " .ms-CommandBar": {
      padding: 0,
      backgroundColor: "transparent",
      border: "none",
      " .ms-OverflowSet-item": { alignItems: "center" },
    },
  },
}));

export const contentHeight = `calc(100vh - ${topHeight})`;
export const pageContentContainer = mergeStyles("Container", {
  height: contentHeight,
  width: "100%",
});

export interface TopBarProps {
  left: ICommandBarItemProps[];
  right?: ICommandBarItemProps[];
}

/**
 * 顶部导航条
 * 移动端暂不显示
 */
export default function TopBar({ left, right }: TopBarProps) {
  const styles = useTopBarStyles();
  return (
    <div className={styles.root}>
      <CommandBar className={styles.commandBar} items={left} farItems={right} />
    </div>
  );
}
