import i18n from "../config/i18n";
import { FeedsLanguage, getStorage, setStorage } from "./storage";

export type FeedsLanguageSuffix = "user" | "admin";

const key = "feedsLanguage";

export function putFeedsLanguage(lngs: FeedsLanguage[], suffix: FeedsLanguageSuffix) {
  setStorage(key, lngs, suffix);
}

export function getFeedsLanguage(suffix: FeedsLanguageSuffix): FeedsLanguage {
  const cacheLanguage = getStorage(key, suffix)?.[0];
  const feedsLanguages = [
    FeedsLanguage.zhCN,
    FeedsLanguage.zhTW,
    FeedsLanguage.zhHK,
    FeedsLanguage.en,
  ];
  return (
    feedsLanguages.find(i => cacheLanguage === i) ??
    feedsLanguages.find(item => i18n.language === item) ??
    (i18n.language.split("-")[0] === "zh" ? FeedsLanguage.zhCN : FeedsLanguage.en)
  );
}
