import { createBrowserHistory, Action } from "history";
import { isElectron } from "../config/browser";
import { PUBLIC_URL } from "../config/config";
import { generateCategoryHash, HASHType, ResourceScope } from "../config/rc";
import { ExtractRouteParams, generateURL, PagePath } from "../config/routes";
import { logger } from "./logger";
import { RCDocumentType, SubEntity, SubEntityType } from "./share";
import { setStorage } from "./storage";

export const history = createBrowserHistory({ window });

let historyLastKey = history.location.key;
let allowLastKeyReplace = true;

export const goBack = () => {
  if (historyLastKey === history.location.key) {
    navigateTo(PagePath.home, { replace: true });
  } else {
    history.back();
  }
};

history.listen(() => {
  if (allowLastKeyReplace && history.action === Action.Replace) {
    historyLastKey = history.location.key;
  } else if (allowLastKeyReplace) {
    allowLastKeyReplace = false;
  }
});

const justReadId = "justReadId";
export function dispatchDeepLink(subEntityId: string) {
  const subEntity: SubEntity = JSON.parse(subEntityId);
  switch (subEntity?.type) {
    case SubEntityType.Link:
      subEntity.body && history.push(subEntity.body);
      break;
    case SubEntityType.document:
    case SubEntityType.bundle:
    case SubEntityType.category:
      const { categoryId, bundleId, docId, documentType, scope = ResourceScope.feeds } = subEntity;
      if (categoryId) {
        history.replace(generateCategoryHash(categoryId, scope));
      } else if (
        scope === ResourceScope.mytenant &&
        [
          RCDocumentType.PPTTemplate,
          RCDocumentType.WordTemplate,
          RCDocumentType.ExcelTemplate,
        ].includes(documentType!)
      ) {
        history.replace(`${HASHType.myTenantTemplates}/`);
      }
      if (bundleId) {
        setStorage(justReadId, bundleId);
        navigateTo(PagePath.bundle, { params: { scope, bundleId } });
      }
      if (docId) {
        setStorage(justReadId, docId);
        navigateTo(PagePath.view, { params: { scope, docId, bundleId } });
      }
      break;
    default:
      logger?.error(subEntityId, "unkown disapatch");
      break;
  }
}

export interface NavigateOptions<T extends PagePath> {
  params?: ExtractRouteParams<T>;
  state?: any;
  replace?: boolean;
  hash?: string;
}

/**
 * page navigation
 * @param page
 * @param options
 */
export function navigateTo<T extends PagePath>(
  page: T,
  { params, state, hash, replace }: NavigateOptions<T> = {}
) {
  let url = generateURL(page, params);
  if (hash) {
    // append hash
    url += hash[0] === "#" ? hash : `#${hash}`;
  }
  if (replace) {
    history.replace(url, state);
  } else {
    history.push(url, state);
  }
}

const SAVE_KEY = `lasthistory${PUBLIC_URL}`;

/**
 * 存储位置
 * 浏览器使用 sessionStorage
 * 客户端使用 localStorage (session每次切换都会销毁)
 */
export const persiststorage = isElectron ? window.localStorage : window.sessionStorage;
/**
 * 保存当前状态
 */
export function saveCurrentHistory() {
  persiststorage.setItem(SAVE_KEY, JSON.stringify(history.location));
}

/**
 * 恢复历史状态
 */
export function recoverLastHistory() {
  const lastHistory = persiststorage.getItem(SAVE_KEY);
  if (lastHistory) {
    const historyParser = JSON.parse(lastHistory);
    history.replace(historyParser, historyParser.state);
  }
  persiststorage.removeItem(SAVE_KEY);
}
