export enum ResourceScope {
  feeds = "feeds",
  mytenant = "mytenant",
}

export enum HASHType {
  feedsCategory = "#/feeds/category",
  myTenantCategory = "#/mytenant/category",
  myTenantContent = "#/mytenant/content",
  myTenantBundles = "#/mytenant/bundles",
  adminAssignment = "#/admin/assignment",
  myTenantTemplates = "#/mytenant/templates",
  myContent = "#/me/content",
}

export enum AdminNavKeys {
  adminContentHub = "content",
  adminBundles = "bundles",
  adminAssignment = "assignment",
  appDeploymentHelp = "help",
}

export enum TemplateNavKeys {
  adminTemplateHub = "templates",
  title = "templateTitle",
}

export enum UserNavKeys {
  userContentHub = "userContent",
}

export function generateCategoryHash(id: string, scope: ResourceScope) {
  switch (scope) {
    case ResourceScope.feeds:
      return `${HASHType.feedsCategory}/${id}`;
    case ResourceScope.mytenant:
      return `${HASHType.myTenantCategory}/${id}`;
  }
}

export enum AdminUserGuideIds {
  createNew = "create-new",
  createCategory = "create-category",
  uploadFile = "upload-file",
}

export enum TreeName {
  myTenantTree = "myTenantTree",
  feedsTree = "feedsTree",
}
