import { Location } from "history";
interface StorageData {
  team: TeamInfo;
  token: Token;
  mpfUser: {
    id: string;
  };
  feedsLanguage: FeedsLanguage[];
  justReadId: string;
  consentClick: boolean;
  deployBarVisibility: boolean;
  flag: true;
  editDocAutoSave: CDS.Document;
  userSelectedTTSVoiceName: string;
  treeNarrowKeys: string[];
  history: Location;
  scrollNum: number;
  expandStatus: boolean;
  readCategoryIds: string[];
  userId: string;
}

export enum FeedsLanguage {
  zhCN = "zh-cn",
  zhTW = "zh-tw",
  zhHK = "zh-hk",
  en = "en",
}

type SuffixType = string | number;

function generateKey(key: string, suffix?: SuffixType) {
  return `$_${key}${suffix ? `.${suffix}` : ""}`;
}

/**
 * 设置本地缓存
 * @param key
 * @param value
 * @param suffix
 */
export function setStorage<T extends keyof StorageData>(
  key: T,
  value: StorageData[T],
  suffix?: SuffixType
) {
  return localStorage.setItem(generateKey(key, suffix), JSON.stringify(value));
}

/**
 * 读取本地缓存
 * @param key
 * @param suffix
 */
export function getStorage<T extends keyof StorageData>(
  key: T,
  suffix?: SuffixType
): StorageData[T] | null {
  return JSON.parse(localStorage.getItem(generateKey(key, suffix))!);
}

export function delStorage<T extends keyof StorageData>(key: T, suffix?: SuffixType) {
  return localStorage.removeItem(generateKey(key, suffix));
}

export interface TeamInfo {
  /**
   * 对应MPF账号ID
   */
  mpfId: string;
  /**
   * 对应GroupID
   */
  gid: string;
}

export interface Token {
  /**
   * MPF 用户ID
   */
  uid: string;
  /**
   * Token 字符串
   */
  token: string;
  /**
   * 过期时间
   */
  exp: number;
}
