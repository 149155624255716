// language resource for localization

// Namespace 和 key 定义到 namespace.ts
// 对应翻译定义到 "*.json"
import zh from "./zh.json";
import en from "./en.json";
import zhtw from "./zh-tw.json";

export * from "./namespace";
export const lngResources = { zh, en, "zh-tw": zhtw };
